.error {
  padding: 3rem 0;
  background-color: #f5f5f5;

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 60px;
    background-color: #fff;
    align-items: center;
    justify-content: center;

    .error_logo {
      height: 200px;
      padding: 20px 0;
    }

    .title {
      width: 56%;
      padding: 0 1.25rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #666;
      margin-bottom: 10px;
      text-align: center;
      line-height: 30px;
    }

    .ul_container {
      display: flex;
      align-items: center;
    }
  }
}
